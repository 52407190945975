"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AudioContextDelegate {
    constructor() {
        this.audioContext = new (AudioContext || webkitAudioContext)();
    }
    close() {
        return this.audioContext.close();
    }
    createGain() {
        return this.audioContext.createGain();
    }
    createBufferSource() {
        return this.audioContext.createBufferSource();
    }
    currentTime() {
        return this.audioContext.currentTime;
    }
    decodeAudioData(audioData) {
        return this.audioContext.decodeAudioData(audioData);
    }
    destination() {
        return this.audioContext.destination;
    }
    resume() {
        return this.audioContext.resume();
    }
    state() {
        return this.audioContext.state;
    }
    suspend() {
        return this.audioContext.suspend();
    }
}
exports.default = AudioContextDelegate;
