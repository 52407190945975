"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundPlayerManager = exports.SoundPlayerManagerState = void 0;
const logger_1 = require("./logger");
const sound_player_1 = require("./sound-player");
/**
 * Represents the current playback state of the {@link SoundPlayerManager}.
 */
var SoundPlayerManagerState;
(function (SoundPlayerManagerState) {
    /**
     * All sounds in the manager are currently in stopped state.
     */
    SoundPlayerManagerState["Idle"] = "idle";
    /**
     * At least one sound in the manager is currently in buffering or playing
     * state.
     */
    SoundPlayerManagerState["Playing"] = "playing";
    /**
     * All sounds in the manager are currently in paused state.
     */
    SoundPlayerManagerState["Paused"] = "paused";
})(SoundPlayerManagerState = exports.SoundPlayerManagerState || (exports.SoundPlayerManagerState = {}));
/**
 * {@link SoundPlayerManager} is responsible for managing the state and
 * lifecycle of {@link SoundPlayer} instances for each and every sound. The
 * class provides methods to set fade-in and fade-out durations, enable/disable
 * premium segments, set the audio bitrate, and adjust the volumes of all sound
 * players. It also provides methods to play, stop and set volumes of individual
 * sounds, and pause, resume and stop all sounds.
 *
 * {@link SoundPlayerManagerState} enum represents playback states of the sound
 * player manager. The manager initialises in
 * {@link SoundPlayerManagerState.Idle} state. There's no terminal state in
 * manager's lifecycle, i.e. its instances always remain functional.
 */
class SoundPlayerManager {
    constructor(cdnClient, logger) {
        this.eventTarget = new EventTarget();
        this.soundPlayers = new Map();
        this.soundPlayerVolumes = new Map();
        this.state = SoundPlayerManagerState.Idle;
        this.fadeInSeconds = 0;
        this.fadeOutSeconds = 0;
        this.audioBitrate = '128k';
        this.isPremiumSegmentsEnabled = false;
        this.volume = 1;
        this.cdnClient = cdnClient;
        this.logger = logger;
    }
    /**
     * Sets the duration for fading in sounds.
     */
    setFadeInSeconds(seconds) {
        this.fadeInSeconds = seconds;
        this.soundPlayers.forEach((player) => player.setFadeInSeconds(seconds));
    }
    /**
     * Sets the duration for fading out sounds.
     */
    setFadeOutSeconds(seconds) {
        this.fadeOutSeconds = seconds;
        this.soundPlayers.forEach((player) => player.setFadeOutSeconds(seconds));
    }
    /**
     * Sets the premium segments enabled flag of all future {@link SoundPlayer}
     * instances, and updates the flag of existing {@link SoundPlayer} instances.
     */
    setPremiumSegmentsEnabled(enabled) {
        if (this.isPremiumSegmentsEnabled === enabled) {
            return;
        }
        this.isPremiumSegmentsEnabled = enabled;
        this.soundPlayers.forEach((player) => player.setPremiumSegmentsEnabled(enabled));
    }
    /**
     * Sets the audio bitrate for streaming sounds.
     *
     * @param bitrate acceptable values are `128k`, `192k`, `256k` and `320k`.
     */
    setAudioBitrate(bitrate) {
        if (this.audioBitrate === bitrate) {
            return;
        }
        this.audioBitrate = bitrate;
        this.soundPlayers.forEach((player) => player.setAudioBitrate(bitrate));
    }
    /**
     * @returns the global multiplier used to scale individual volumes of all
     * sounds.
     */
    getVolume() {
        return this.volume;
    }
    /**
     * Sets a global multiplier used to scale individual volumes of all sounds.
     *
     * @param volume must be >= 0 and <= 1.
     * @throws Error if the volume is not within the accepted range.
     */
    setVolume(volume) {
        if (volume < 0 || volume > 1) {
            throw new Error('volume must be in range [0, 1]');
        }
        this.volume = volume;
        this.soundPlayers.forEach((player, soundId) => { var _a; return player.setVolume(volume * ((_a = this.soundPlayerVolumes.get(soundId)) !== null && _a !== void 0 ? _a : 1)); });
        this.dispatchEvent(SoundPlayerManager.EVENT_VOLUME_CHANGE);
    }
    /**
     * @returns the volume of specific sound without taking the global multiplier
     * into account.
     */
    getSoundVolume(soundId) {
        var _a;
        return (_a = this.soundPlayerVolumes.get(soundId)) !== null && _a !== void 0 ? _a : 1;
    }
    /**
     * Sets the volume of the specified sound.
     *
     * @param volume must be >= 0 and <= 1.
     * @throws Error if the volume is not within the accepted range.
     */
    setSoundVolume(soundId, volume) {
        var _a;
        if (volume < 0 || volume > 1) {
            throw new Error('volume must be in range [0, 1]');
        }
        this.soundPlayerVolumes.set(soundId, volume);
        (_a = this.soundPlayers.get(soundId)) === null || _a === void 0 ? void 0 : _a.setVolume(this.volume * volume);
        this.dispatchSoundEvent(soundId, SoundPlayerManager.EVENT_VOLUME_CHANGE);
    }
    /**
     * @returns the current {@link SoundPlayerManagerState} of this instance.
     */
    getState() {
        return this.state;
    }
    /**
     * @returns the current {@link SoundPlayerState} of the specified sound.
     */
    getSoundState(soundId) {
        var _a, _b;
        return ((_b = (_a = this.soundPlayers.get(soundId)) === null || _a === void 0 ? void 0 : _a.getState()) !== null && _b !== void 0 ? _b : sound_player_1.SoundPlayerState.Stopped);
    }
    /**
     * Plays the specified sound. It also resumes all sounds if the
     * {@link SoundPlayerManager} is in the
     * {@link SoundPlayerManagerState.Paused}.
     */
    playSound(soundId) {
        var _a;
        const player = (_a = this.soundPlayers.get(soundId)) !== null && _a !== void 0 ? _a : this.initSoundPlayer(soundId);
        if (this.state === SoundPlayerManagerState.Paused) {
            this.resume();
        }
        else {
            player.play();
        }
    }
    /**
     * Stops the specified sound.
     */
    stopSound(soundId) {
        var _a;
        (_a = this.soundPlayers.get(soundId)) === null || _a === void 0 ? void 0 : _a.stop(false);
    }
    /**
     * Resumes all sounds that are in {@link SoundPlayerState.Paused}.
     */
    resume() {
        this.soundPlayers.forEach((player) => player.play());
    }
    /**
     * Pauses all sounds with a fade-out effect.
     */
    pause() {
        // If all sounds are stopping, we should transition to pausing state. If
        // only some sounds are stopping, we should exempt them from pausing.
        const isManagerStopping = this.state === SoundPlayerManagerState.Idle;
        this.soundPlayers.forEach((player) => {
            if (player.getState() !== sound_player_1.SoundPlayerState.Stopping ||
                isManagerStopping) {
                player.pause(false);
            }
        });
    }
    /**
     * Stops all sounds immediately or with a fade-out effect
     *
     * @param immediate whether the stop should be immediate or if the sounds
     * should perform a fade-out effect before stopping.
     */
    stop(immediate) {
        this.soundPlayers.forEach((player) => player.stop(immediate));
    }
    /**
     * Registers a callback that is invoked every time
     * {@link SoundPlayerManagerState} changes.
     */
    addStateListener(callback) {
        this.eventTarget.addEventListener(SoundPlayerManager.EVENT_STATE_CHANGE, callback);
    }
    /**
     * Removes a previous registered callback to listen for state changes.
     */
    removeStateListener(callback) {
        this.eventTarget.removeEventListener(SoundPlayerManager.EVENT_STATE_CHANGE, callback);
    }
    /**
     * Registers a callback that is invoked every time volume changes.
     */
    addVolumeListener(callback) {
        this.eventTarget.addEventListener(SoundPlayerManager.EVENT_VOLUME_CHANGE, callback);
    }
    /**
     * Removes a previous registered callback to listen for volume changes.
     */
    removeVolumeListener(callback) {
        this.eventTarget.removeEventListener(SoundPlayerManager.EVENT_VOLUME_CHANGE, callback);
    }
    /**
     * Registers a callback that is invoked every time {@link SoundPlayerState}
     * of the specified sound changes.
     */
    addSoundStateListener(soundId, callback) {
        // do not register listeners on the sound players because they are
        // disposable, and an instance may not be available at the time of
        // registration.
        this.eventTarget.addEventListener(`${soundId}.${SoundPlayerManager.EVENT_STATE_CHANGE}`, callback);
    }
    /**
     * Removes a previously registered callback to listen for state changes of the
     * specified sound.
     */
    removeSoundStateListener(soundId, callback) {
        this.eventTarget.removeEventListener(`${soundId}.${SoundPlayerManager.EVENT_STATE_CHANGE}`, callback);
    }
    /**
     * Registers a callback that is invoked every time volume of the specified
     * sound changes.
     */
    addSoundVolumeListener(soundId, callback) {
        this.eventTarget.addEventListener(`${soundId}.${SoundPlayerManager.EVENT_VOLUME_CHANGE}`, callback);
    }
    /**
     * Removes a previously registered callback to listen for volume changes of
     * the specified sound.
     */
    removeSoundVolumeListener(soundId, callback) {
        this.eventTarget.removeEventListener(`${soundId}.${SoundPlayerManager.EVENT_STATE_CHANGE}`, callback);
    }
    initSoundPlayer(soundId) {
        var _a;
        const logger = (0, logger_1.createNamedLogger)(this.logger, `SoundPlayer(${soundId})`);
        const player = new sound_player_1.SoundPlayer(this.cdnClient, soundId, logger);
        this.soundPlayers.set(soundId, player);
        this.setSoundVolume(soundId, (_a = this.soundPlayerVolumes.get(soundId)) !== null && _a !== void 0 ? _a : 1);
        player.setFadeInSeconds(this.fadeInSeconds);
        player.setFadeOutSeconds(this.fadeOutSeconds);
        player.setPremiumSegmentsEnabled(this.isPremiumSegmentsEnabled);
        player.setAudioBitrate(this.audioBitrate);
        player.addEventListener(sound_player_1.SoundPlayer.EVENT_STATE_CHANGE, () => this.onSoundPlayerStateChangeEvent(soundId));
        return player;
    }
    onSoundPlayerStateChangeEvent(soundId) {
        var _a;
        const playerState = (_a = this.soundPlayers.get(soundId)) === null || _a === void 0 ? void 0 : _a.getState();
        if (playerState == null) {
            return;
        }
        if (playerState === sound_player_1.SoundPlayerState.Stopped) {
            this.soundPlayers.delete(soundId);
        }
        this.dispatchSoundEvent(soundId, SoundPlayerManager.EVENT_STATE_CHANGE);
        this.reconcileState();
    }
    reconcileState() {
        let isPaused = true;
        let isIdle = true;
        for (const soundPlayer of this.soundPlayers.values()) {
            const soundState = soundPlayer.getState();
            if (soundState !== sound_player_1.SoundPlayerState.Stopping && // some sounds may be stopping
                soundState !== sound_player_1.SoundPlayerState.Pausing &&
                soundState !== sound_player_1.SoundPlayerState.Paused) {
                isPaused = false;
            }
            if (soundState !== sound_player_1.SoundPlayerState.Stopping &&
                soundState !== sound_player_1.SoundPlayerState.Stopped) {
                isIdle = false;
            }
        }
        const managerState = isIdle
            ? SoundPlayerManagerState.Idle
            : isPaused
                ? SoundPlayerManagerState.Paused
                : SoundPlayerManagerState.Playing;
        if (this.state === managerState) {
            return;
        }
        this.state = managerState;
        this.dispatchEvent(SoundPlayerManager.EVENT_STATE_CHANGE);
    }
    dispatchEvent(event) {
        this.eventTarget.dispatchEvent(new Event(event));
    }
    dispatchSoundEvent(soundId, event) {
        this.dispatchEvent(`${soundId}.${event}`);
    }
}
exports.SoundPlayerManager = SoundPlayerManager;
SoundPlayerManager.EVENT_STATE_CHANGE = 'statechange';
SoundPlayerManager.EVENT_VOLUME_CHANGE = 'volumechange';
