"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundPlayer = exports.SoundPlayerState = void 0;
const logger_1 = require("./logger");
const media_player_1 = require("./media-player");
var SoundPlayerState;
(function (SoundPlayerState) {
    SoundPlayerState["Buffering"] = "buffering";
    SoundPlayerState["Playing"] = "playing";
    SoundPlayerState["Pausing"] = "pausing";
    SoundPlayerState["Paused"] = "paused";
    SoundPlayerState["Stopping"] = "stopping";
    SoundPlayerState["Stopped"] = "stopped";
})(SoundPlayerState = exports.SoundPlayerState || (exports.SoundPlayerState = {}));
class SoundPlayer extends EventTarget {
    constructor(cdnClient, soundId, logger) {
        super();
        this.segments = [];
        this.maxSilenceSeconds = 0;
        this.fadeInSeconds = 0;
        this.fadeOutSeconds = 0;
        this.isPremiumSegmentsEnabled = false;
        this.audioBitrate = '128k';
        this.volume = 1;
        this.state = SoundPlayerState.Paused;
        this.hasLoadedMetadata = false;
        this.shouldPlayOnLoadingMetadata = false;
        this.metadataRetryDelayMillis = SoundPlayer.MIN_RETRY_DELAY_MILLIS;
        this.shouldFadeIn = false;
        this.cdnClient = cdnClient;
        this.logger = logger;
        this.mediaPlayer = new media_player_1.MediaPlayer(15, cdnClient, (0, logger_1.createNamedLogger)(this.logger, `MediaPayer(${soundId})`));
        this.mediaPlayer.addEventListener(media_player_1.MediaPlayer.EVENT_ITEM_TRANSITION, () => this.onMediaPlayerItemTransition());
        this.mediaPlayer.addEventListener(media_player_1.MediaPlayer.EVENT_STATE_CHANGE, () => this.onMediaPlayerStateChange());
        this.metadataLoadTimeout = setTimeout(() => this.loadMetadata(soundId), 0);
    }
    onMediaPlayerItemTransition() {
        var _a;
        if (this.state === SoundPlayerState.Stopped) {
            return;
        }
        if (this.maxSilenceSeconds > 0 &&
            this.mediaPlayer.getMediaItemCount() === 0) {
            const d = 30 + Math.floor(Math.random() * (this.maxSilenceSeconds - 29));
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.debug(`scheduling next segment after ${d}s`);
            this.queueNextSegmentTimeout = setTimeout(() => this.queueNextSegment(), d * 1000);
        }
        if (this.maxSilenceSeconds === 0 &&
            this.mediaPlayer.getMediaItemCount() < 2) {
            this.queueNextSegment();
        }
    }
    onMediaPlayerStateChange() {
        var _a;
        (_a = this.logger) === null || _a === void 0 ? void 0 : _a.debug(`media player state: ${this.mediaPlayer.getState()}`);
        if (this.shouldFadeIn &&
            this.mediaPlayer.getState() === "playing" /* MediaPlayerState.Playing */) {
            this.mediaPlayer.fadeTo(this.getScaledVolume(), this.fadeInSeconds);
            this.shouldFadeIn = false;
        }
        switch (this.mediaPlayer.getState()) {
            case "buffering" /* MediaPlayerState.Buffering */:
                this.setState(SoundPlayerState.Buffering);
                break;
            case "paused" /* MediaPlayerState.Paused */:
                this.setState(SoundPlayerState.Paused);
                break;
            case "stopped" /* MediaPlayerState.Stopped */:
                this.setState(SoundPlayerState.Stopped);
                break;
            default:
                // do not overwrite pausing and stopping state.
                if (this.state !== SoundPlayerState.Pausing &&
                    this.state !== SoundPlayerState.Stopping) {
                    this.setState(SoundPlayerState.Playing);
                }
                break;
        }
    }
    queueNextSegment() {
        var _a, _b;
        const validSegments = this.isPremiumSegmentsEnabled
            ? this.segments
            : this.segments.filter((s) => s.isFree);
        let next = undefined;
        if ((_a = this.currentSegment) === null || _a === void 0 ? void 0 : _a.isBridge) {
            // contiguous sound is playing a bridge segment, find its destination!
            next = validSegments.find((s) => { var _a; return s.name === ((_a = this.currentSegment) === null || _a === void 0 ? void 0 : _a.to); });
        }
        else if (this.currentSegment != null && this.maxSilenceSeconds === 0) {
            // contiguous sound is playing a regular segment, find a bridge!
            const validBridges = validSegments.filter((s) => { var _a; return s.from === ((_a = this.currentSegment) === null || _a === void 0 ? void 0 : _a.name); });
            next = validBridges[Math.floor(Math.random() * validBridges.length)];
        }
        else {
            // either no segment had been played yet or this is not a contiguous
            // sound.
            next = validSegments[Math.floor(Math.random() * validSegments.length)];
        }
        if (next == null) {
            throw new Error("couldn't find a valid segment to queue next");
        }
        this.currentSegment = next;
        this.mediaPlayer.addToPlaylist(`library/${next.basePath}/${this.audioBitrate}/index.jan`);
        (_b = this.logger) === null || _b === void 0 ? void 0 : _b.info(`queued segment: ${next.name}`);
    }
    getState() {
        return this.state;
    }
    setFadeInSeconds(seconds) {
        this.fadeInSeconds = seconds;
    }
    setFadeOutSeconds(seconds) {
        this.fadeOutSeconds = seconds;
    }
    setPremiumSegmentsEnabled(enabled) {
        if (enabled === this.isPremiumSegmentsEnabled) {
            return;
        }
        this.isPremiumSegmentsEnabled = enabled;
        this.currentSegment = undefined;
        this.mediaPlayer.clearPlaylist(); // media player will trigger item transition event.
    }
    setAudioBitrate(bitrate) {
        if (bitrate === this.audioBitrate) {
            return;
        }
        this.audioBitrate = bitrate;
        this.currentSegment = undefined;
        this.mediaPlayer.clearPlaylist(); // media player will trigger item transition event.
    }
    setVolume(volume) {
        this.volume = volume;
        if (this.mediaPlayer.getState() === "playing" /* MediaPlayerState.Playing */) {
            this.mediaPlayer.fadeTo(this.getScaledVolume(), 1.5);
        }
        else {
            this.mediaPlayer.setVolume(this.getScaledVolume());
        }
    }
    getScaledVolume() {
        return Math.pow(this.volume, 2);
    }
    play() {
        if (this.state === SoundPlayerState.Stopped) {
            throw new Error('cannot re-use a stopped sound player');
        }
        if (this.mediaPlayer.getState() === "playing" /* MediaPlayerState.Playing */) {
            this.setState(SoundPlayerState.Playing);
            this.mediaPlayer.fadeTo(this.getScaledVolume(), this.fadeInSeconds);
            return;
        }
        if (!this.hasLoadedMetadata) {
            // set our state to buffering and auto start when the metadata finishes
            // loading.
            this.shouldPlayOnLoadingMetadata = true;
            this.setState(SoundPlayerState.Buffering);
        }
        else {
            if (this.mediaPlayer.getMediaItemCount() === 0) {
                this.queueNextSegment();
            }
            this.shouldFadeIn = true;
            this.mediaPlayer.setVolume(0);
            this.mediaPlayer.play();
        }
    }
    pause(immediate) {
        if (this.shouldPlayOnLoadingMetadata) {
            this.shouldPlayOnLoadingMetadata = false;
            this.setState(SoundPlayerState.Paused);
            return;
        }
        clearTimeout(this.queueNextSegmentTimeout);
        if (immediate || this.mediaPlayer.getState() !== "playing" /* MediaPlayerState.Playing */) {
            this.mediaPlayer.pause();
            return;
        }
        this.setState(SoundPlayerState.Pausing);
        this.mediaPlayer.fadeTo(0, this.fadeOutSeconds, () => this.mediaPlayer.pause());
    }
    stop(immediate) {
        clearTimeout(this.metadataLoadTimeout);
        clearTimeout(this.queueNextSegmentTimeout);
        if (immediate || this.mediaPlayer.getState() !== "playing" /* MediaPlayerState.Playing */) {
            this.mediaPlayer.stop();
            return;
        }
        this.setState(SoundPlayerState.Stopping);
        this.mediaPlayer.fadeTo(0, this.fadeOutSeconds, () => this.mediaPlayer.stop());
    }
    loadMetadata(soundId) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.debug('start loading sound metadata');
            try {
                const response = yield this.cdnClient.getResource('library/library-manifest.json');
                if (!response.ok) {
                    throw new Error(`http error: ${response.status} ${response.statusText}`);
                }
                const manifest = yield response.json();
                const sound = manifest.sounds.find((s) => s.id === soundId);
                if (sound == null) {
                    throw new Error('sound not found');
                }
                this.maxSilenceSeconds = sound.maxSilence;
                this.segments.length = 0;
                const segmentsBasePath = `${manifest.segmentsBasePath}/${sound.id}`;
                sound.segments.forEach((segment) => {
                    this.segments.push({
                        name: segment.name,
                        basePath: `${segmentsBasePath}/${segment.name}`,
                        isFree: segment.isFree,
                        isBridge: false,
                    });
                    if (sound.maxSilence === 0) {
                        sound.segments.forEach((toSegment) => {
                            const bridgeName = `${segment.name}_${toSegment.name}`;
                            this.segments.push({
                                name: bridgeName,
                                basePath: `${segmentsBasePath}/${bridgeName}`,
                                isFree: segment.isFree && toSegment.isFree,
                                isBridge: true,
                                from: segment.name,
                                to: toSegment.name,
                            });
                        });
                    }
                });
                (_b = this.logger) === null || _b === void 0 ? void 0 : _b.debug('finished loading sound metadata');
                this.hasLoadedMetadata = true;
                this.metadataRetryDelayMillis = SoundPlayer.MIN_RETRY_DELAY_MILLIS;
                if (this.shouldPlayOnLoadingMetadata) {
                    this.shouldPlayOnLoadingMetadata = false;
                    this.play();
                }
            }
            catch (error) {
                this.metadataRetryDelayMillis = Math.min(this.metadataRetryDelayMillis * 2, SoundPlayer.MAX_RETRY_DELAY_MILLIS);
                (_c = this.logger) === null || _c === void 0 ? void 0 : _c.warn(`failed to load sound metadata, retrying in ${(this.metadataRetryDelayMillis / 1000).toPrecision(2)}s`);
                this.metadataLoadTimeout = setTimeout(() => this.loadMetadata(soundId), this.metadataRetryDelayMillis);
            }
        });
    }
    setState(state) {
        if (this.state === state) {
            return;
        }
        this.state = state;
        this.dispatchEvent(new Event(SoundPlayer.EVENT_STATE_CHANGE));
    }
}
exports.SoundPlayer = SoundPlayer;
SoundPlayer.EVENT_STATE_CHANGE = 'statechange';
SoundPlayer.MIN_RETRY_DELAY_MILLIS = 1 * 1000;
SoundPlayer.MAX_RETRY_DELAY_MILLIS = 30 * 1000;
