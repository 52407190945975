"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsoleLogger = exports.ConsoleLogLevel = exports.createNamedLogger = void 0;
class NamedLogger {
    constructor(parent, name) {
        this.parent = parent;
        this.name = name;
    }
    debug(message) {
        this.parent.debug(`${this.name}: ${message}`);
    }
    info(message) {
        this.parent.info(`${this.name}: ${message}`);
    }
    warn(message) {
        this.parent.warn(`${this.name}: ${message}`);
    }
}
function createNamedLogger(parent, name) {
    return parent ? new NamedLogger(parent, name) : undefined;
}
exports.createNamedLogger = createNamedLogger;
var ConsoleLogLevel;
(function (ConsoleLogLevel) {
    ConsoleLogLevel[ConsoleLogLevel["Debug"] = 0] = "Debug";
    ConsoleLogLevel[ConsoleLogLevel["Info"] = 1] = "Info";
    ConsoleLogLevel[ConsoleLogLevel["Warn"] = 2] = "Warn";
})(ConsoleLogLevel = exports.ConsoleLogLevel || (exports.ConsoleLogLevel = {}));
class ConsoleLogger {
    constructor(minLevel) {
        this.minLogLevel = minLevel !== null && minLevel !== void 0 ? minLevel : ConsoleLogLevel.Debug;
    }
    debug(message) {
        if (this.minLogLevel <= ConsoleLogLevel.Debug) {
            console.debug(message);
        }
    }
    info(message) {
        if (this.minLogLevel <= ConsoleLogLevel.Info) {
            console.info(message);
        }
    }
    warn(message) {
        if (this.minLogLevel <= ConsoleLogLevel.Warn) {
            console.warn(message);
        }
    }
}
exports.ConsoleLogger = ConsoleLogger;
